const Styles = {
	MenuBar: {
		MenuBarContainer: {
			position: "absolute",
			display: "flex",
			flexDirection: "row",
			height: "5vh",
			width: "100vw",
			background: "rgba(83, 83, 83, 0.4)",
		},
		mobileMenuBarContainer: {
			position: "absolute",
			display: "flex",
			flexDirection: "row",
			height: "5vh",
			width: "100vw",
			background: "transparent",
		},
		MenuBarRight: {
			display: "flex",
			listStyleType: "none",
			justifyContent: "right",
			alignItems: "center",
			width: "100vw",
			background: "transparent",
		},
		mobileMenuBarRight: {
			display: "flex",
			listStyleType: "none",
			justifyContent: "right",
			alignItems: "center",
			width: "100vw",
			background: "transparent",
		},
		MenuBarLeft: {
			display: "flex",
			justifyContent: "left",
			alignItems: "center",
			width: "100vw",
			background: "transparent",
		},
		mobileMenuBarLeft: {
			display: "flex",
			justifyContent: "left",
			alignItems: "center",
			width: "100vw",
			background: "transparent",
		},
		MenuBarDateTime: {
			display: "flex",
			justifyContent: "space-between",
			alignItems: "center",
			marginRight: "2vw",
			width: "11.5vw",
			color: "white",
			fontFamily: "Space Mono"
		},
		mobileMenuBarDateTime: {
			display: "flex",
			justifyContent: "space-between",
			alignItems: "center",
			marginRight: "2vw",
			width: "auto",
			color: "white",
			fontFamily: "Space Mono",
			fontSize: "15px",
		},
		MeIcon: {
			width: "3vw",
			paddingLeft: "2vw",
		},
		mobileMeIcon: {
			width: "7.5vw",
			paddingLeft: "2vw",
		},
		Title: {
			color: "white",
			paddingLeft: "1vw",
			fontSize: "24px",
			fontFamily: "Space Mono"
		},
		mobileTitle: {
			color: "white",
			paddingLeft: "2vw",
			fontSize: "14px",
			fontFamily: "Space Mono"
		}
	},
	SideBar: {
		SideBar: {
			position: "relative",
			display: "flex",
			flexDirection: "column",
			justifyContent: "space-evenly",
			alignItems: "center",
			width: "auto",
			height: "auto",
			maxWidth: "5vw",
			maxHeight: "100vw",
			borderRadius: "16px",
			background: "rgba(83, 83, 83, 0.25",
			backdropFilter: "blur(13px)",
			WebkitBackdropFilter: "blur(13px)",
			border: "1px solid rgba(255, 255, 255, 0.18",
			marginLeft: "1vw",
		},
		SideBarContainer: {
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			justifyContent: "center",
			width: "auto",
			height: "100vh",
		},
		mobileSideBar: {
			position: "relative",
			display: "flex",
			flexDirection: "row",
			justifyContent: "space-evenly",
			alignItems: "center",
			width: "100vw",
			height: "10vh",
			maxWidth: "95vw",
			maxHeight: "10vh",
			borderRadius: "20px",
			background: "rgba(83, 83, 83, 0.25",
			backdropFilter: "blur(13px)",
			WebkitBackdropFilter: "blur(13px)",
			border: "1px solid rgba(255, 255, 255, 0.18",
			marginBottom: "1vh",
		},
		mobileSideBarContainer: {
			position: "absolute",
			display: "flex",
			flexDirection: "row",
			alignItems: "flex-end",
			justifyContent: "center",
			width: "100vw",
			height: "100vh",
		}
	},
	Modal: {
		modalContainer: {
			display:"flex",
			justifyContent: "center",
			alignItems: "center",
			height: "100vh",
			width: "100vw",
		},
		mobileModalContainer: {
			position: "absolute",
			display:"flex",
			justifyContent: "center",
			alignItems: "center",
			height: "auto",
			width: "100vw",
		},
		modal: {
			position: "relative",
			display:"flex",
			width:"75vw",
			height: "75vh",
			backgroundColor: "white",
			borderRadius: "16px",
			overflow: "hidden",
		},
		mobileModal: {
			display: "flex",
			backgroundColor: "white",
			width: "100vw",
			height: "88.5vh",
		},
		modalContent: {
			display: "flex",
			flexDirection: "column",
			padding:"5rem",
			justifyContent: "left",
			alignItems: "flex-start",
			width: "100vw",
			overflowY: "auto",
		},
		mobileModalContent: {
			display: "flex",
			flexDirection: "column",
			padding:"5rem 2rem 5rem 2rem",
			justifyContent: "left",
			alignItems: "flex-start",
			width: "100vw",
			overflowY: "auto",
		},
		modalTitleBar: {
			position: "absolute",
			display: "flex",
			flexDirection: "row",
			justifyContent: "left",
			alignItems: "center",
			width: "100%",
			height: "7vh",
			borderBottom: "2px solid black",
			backgroundColor: "#696969",
			borderRadius: "14px 14px 0px 0px"
		},
		titleBarContent: {
			fontWeight: "bold",
			fontSize: "24px",
			color: "white",
			fontFamily: "Space Mono",
		},
		textStyle: {
			display: "flex",
			flexDirection: "column",
			justifyContent: "left",
			alignItems: "flex-start",
			overflowWrap: "normal",
			fontFamily: "Poppins",
			projectLinks: {
				fontFamily: "Poppins",
			},
			"&#contactInfo": {
				listStyle: "none",
				justifyContent: "space-evenly",
				alignItems: "flex-start",
				height: "100vh",
			},
		},
		closeButtonStyle: {
			display: "flex",
			justifyContent: "right",
			alignItems: "right",
			position: "absolute",
			right: "0px",
			height: "25px",
			width: "25px",
			padding: "1.5vw",
		}
	},
	Icon: {
		icon: {
			display: "flex",
			width: "3.5vw",
			translateY: "0px",
			transition: "0.225s",
			borderRadius: "16px",
		},
		mobileIcon: {
		  display: "flex",
		  width: "14vw",
		  translateY: "0px",
		  transition: "0.225s",
		  borderRadius: "16px",
		},
		menuBarIcons: {
			display: "flex",
			width: "1.5vw",
			backgroundColor: "white",
			borderRadius: "50%",
			padding: ".2vw",
		},
		mobileMenuBarIcons: {
		  position: "absolute",
		  display: "flex",
		  width: "13.5vw",
		  backgroundColor: "white",
		  borderRadius: "25%",
		  padding: ".2vw",
		},
		iconHover: {
			width: "7vw",
			marginLeft: "50px",
			transition: "0.225s",
			borderRadius: "32px",
		},
		iconContainer: {
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			justifyContent: "center",
			padding: "1vw",
			width: "50px",
			height: "50px",
			transition: "0.225s",
		},
		mobileIconContainer: {
		  width: "15vw",
		  height: "6.5vh",
		  marginLeft: "10vw",
		},
		containerHover: {
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			justifyContent: "center",
			padding: "1vw",
			margin: "5px 0 5px 0",
			transition: "0.225s",
		},
		iconNameDisplay: {
			position: "absolute",
			display: "flex",
			flexDirection: "row",
			marginLeft: "300px",
			justifyContent: "center",
			alignItems: "center",
			background: "rgba(0, 0, 0, 0.5)",
			color: "rgba(255, 255, 255, 0.9)",
			padding: "8px",
			width: "100%",
			backDropFilter: "blur(13px)",
			WebkitBackdropFilter: "blur(13px)",
			borderRadius: "5px",
			fontFamily: "Poppins",
			hideName: {
				display: "none",
			}
		}
	},
	MobileStyles: {
		MenuBar: {
			MenuBarContainer: {
				position: "absolute",
				display: "flex",
				flexDirection: "row",
				height: "5vh",
				width: "100vw",
				background: "transparent",
				// marginTop: "10vh",
			},
			MenuBarRight: {
				display: "flex",
				listStyleType: "none",
				justifyContent: "right",
				alignItems: "center",
				width: "100vw",
				background: "transparent",
			},
			MenuBarLeft: {
				display: "flex",
				justifyContent: "left",
				alignItems: "center",
				width: "100vw",
				background: "transparent",
			},
			MenuBarDateTime: {
				display: "flex",
				justifyContent: "space-between",
				alignItems: "center",
				marginRight: "2vw",
				width: "auto",
				color: "white",
				fontFamily: "Space Mono",
				fontSize: "15px",
			},
			MeIcon: {
				width: "7.5vw",
				paddingLeft: "2vw",
			},
			Title: {
				color: "white",
				paddingLeft: "2vw",
				fontSize: "14px",
				fontFamily: "Space Mono"
			},
		},
		Icon: {
			icon: {
				display: "flex",
				width: "15vw",
				borderRadius: "16px",
			},
			menuBarIcons: {
				position: "absolute",
				display: "flex",
				width: "13.5vw",
				backgroundColor: "white",
				borderRadius: "25%",
				padding: ".2vw",
			},
			iconContainer: {
				width: "15vw",
				height: "7vh",
			},
			iconNameDisplay: {
				position: "absolute",
				display: "flex",
				flexDirection: "row",
				marginLeft: "300px",
				justifyContent: "center",
				alignItems: "center",
				background: "rgba(0, 0, 0, 0.5)",
				color: "rgba(255, 255, 255, 0.9)",
				padding: "8px",
				width: "100%",
				backDropFilter: "blur(13px)",
				WebkitBackdropFilter: "blur(13px)",
				borderRadius: "5px",
				fontFamily: "Poppins",
				hideName: {
					display: "none",
				}
			}
		},
		SideBar: {
			SideBar: {
				position: "relative",
				display: "flex",
				flexDirection: "row",
				justifyContent: "space-evenly",
				alignItems: "center",
				width: "100vw",
				height: "10vh",
				maxWidth: "95vw",
				maxHeight: "10vh",
				borderRadius: "20px",
				background: "rgba(83, 83, 83, 0.25",
				backdropFilter: "blur(13px)",
				WebkitBackdropFilter: "blur(13px)",
				border: "1px solid rgba(255, 255, 255, 0.18",
				marginBottom: "1.5vh",
			},
			SideBarContainer: {
				display: "flex",
				flexDirection: "row",
				alignItems: "flex-end",
				justifyContent: "center",
				width: "100vw",
			},
		},
		Modal: {
			modalContainer: {
				position: "absolute",
				display:"flex",
				justifyContent: "center",
				alignItems: "center",
				height: "auto",
				width: "100vw",
			},
			modal: {
				display: "flex",
				backgroundColor: "white",
				width: "100vw",
				height: "79.5vh",
				borderRadius: "14px"
			},
			modalContent: {
				display: "flex",
				flexDirection: "column",
				padding:"5rem 2rem 5rem 2rem",
				justifyContent: "left",
				alignItems: "flex-start",
				width: "100vw",
				overflowY: "auto",
			},
			modalTitleBar: {
				position: "absolute",
				display: "flex",
				flexDirection: "row",
				justifyContent: "left",
				alignItems: "center",
				width: "100%",
				height: "7vh",
				borderBottom: "2px solid black",
				backgroundColor: "#696969",
				borderRadius: "14px 14px 0px 0px"
			},
			titleBarContent: {
				fontWeight: "bold",
				fontSize: "24px",
				color: "white",
				fontFamily: "Space Mono",
			},
			textStyle: {
				display: "flex",
				flexDirection: "column",
				justifyContent: "left",
				alignItems: "flex-start",
				overflowWrap: "normal",
				fontFamily: "Poppins",
				projectLinks: {
					fontFamily: "Poppins",
				},
				"&#contactInfo": {
					listStyle: "none",
					justifyContent: "space-evenly",
					alignItems: "flex-start",
					height: "100vh",
				},
			},
			closeButtonStyle: {
				display: "flex",
				justifyContent: "right",
				alignItems: "right",
				position: "absolute",
				right: "0px",
				height: "25px",
				width: "25px",
				padding: "1.5vw",
			}
		},
	}
}

export default Styles;
